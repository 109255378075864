import { Block, BlockInputOutputType } from 'src/app/models/blocks/base';

export function getStorageBlocks(): Block {
  return {
    'graph-database': {
      ingest: {
        name: 'Store in',
        inputTypes: [BlockInputOutputType.File, BlockInputOutputType.Text, BlockInputOutputType.Index, BlockInputOutputType.Entity, BlockInputOutputType.Relation],
      },
      retrieve: {
        name: 'Retrieve from',
        inputTypes: [BlockInputOutputType.Text, BlockInputOutputType.Search, BlockInputOutputType.Entity],
        outputTypes: [BlockInputOutputType.Search],
        attributes: {
          maxDepth: 1,
          contentSize: 0,
          minRelevance: 0.2,
          topK: 5,
        },
      }
    },
    'vector-database': {
      ingest: {
        name: 'Store in',
        inputTypes: [BlockInputOutputType.File, BlockInputOutputType.Text, BlockInputOutputType.Index, BlockInputOutputType.Vector],
      },
      retrieve: {
        name: 'Retrieve from',
        inputTypes: [BlockInputOutputType.Text, BlockInputOutputType.Vector],
        outputTypes: [BlockInputOutputType.Search],
        attributes: {
          contentSize: 0,
          minRelevance: 0.2,
          topK: 5,
        },
      },
    },
  };
}
