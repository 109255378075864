import { Block, BlockInputOutputType } from 'src/app/models/blocks/base';

export function getSkillBlocks(): Block {
  return {
    'agent-skill': {
      workflow: {
        name: 'Run',
        inputTypes: [BlockInputOutputType.Context, BlockInputOutputType.Knowledge, BlockInputOutputType.Skill],
        outputTypes: [BlockInputOutputType.Skill, BlockInputOutputType.Text, BlockInputOutputType.Context],
      },
    },
    'custom-skill': {
      workflow: {
        name: 'Execute',
        inputTypes: [BlockInputOutputType.Context],
        outputTypes: [BlockInputOutputType.Skill, BlockInputOutputType.Context],
      },
    },
    code: {
      workflow: {
        name: 'Code Execution',
        inputTypes: [],
        outputTypes: [BlockInputOutputType.Skill],
      },
    },
    search: {
      workflow: {
        name: 'Web Search',
        inputTypes: [],
        outputTypes: [BlockInputOutputType.Skill],
      },
    },
    'ai-search': {
      workflow: {
        name: 'AI Search',
        inputTypes: [],
        outputTypes: [BlockInputOutputType.Skill],
      },
    },
    'web-scraper': {
      workflow: {
        name: 'Web Page Reader',
        inputTypes: [],
        outputTypes: [BlockInputOutputType.Skill],
      },
    },
    'web-scraper-raw': {
      workflow: {
        name: 'Raw Content Extractor',
        inputTypes: [],
        outputTypes: [BlockInputOutputType.Skill],
      },
    },
    'query-file': {
      workflow: {
        name: 'Query File',
        inputTypes: [BlockInputOutputType.File],
        outputTypes: [BlockInputOutputType.Skill],
      },
    },
  };
}