import { Block, BlockInputOutputType } from 'src/app/models/blocks/base';

export function getDataCollectionBlocks(): Block {
  return {
    template: {
      workflow: {
        name: 'Add context from',
        inputTypes: [BlockInputOutputType.Text],
        outputTypes: [BlockInputOutputType.Wait, BlockInputOutputType.Context],
      },
    },
  };
}
