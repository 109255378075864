import { Block, BlockInputOutputType } from 'src/app/models/blocks/base';

export function getFunctionBlocks(): Block {
  return {
    'document-parser': {
      ingest: {
        name: 'Read file',
        inputTypes: [BlockInputOutputType.File],
        outputTypes: [BlockInputOutputType.Text, BlockInputOutputType.Index, BlockInputOutputType.Audio, BlockInputOutputType.Image],
      },
      workflow: {
        name: 'Read file',
        inputTypes: [BlockInputOutputType.File],
        outputTypes: [BlockInputOutputType.Context],
      },
    },
    'chunk-splitter': {
      ingest: {
        name: 'Split text into chunks',
        inputTypes: [BlockInputOutputType.Text, BlockInputOutputType.Index],
        outputTypes: [BlockInputOutputType.Index],
        attributes: {
          size: 250,
          overlap: 50,
        },
      },
      workflow: {
        name: 'Split text into chunks',
        inputTypes: [BlockInputOutputType.Text],
        outputTypes: [BlockInputOutputType.Text],
        attributes: {
          size: 250,
          overlap: 50,
        },
      },
    },
    'sentence-splitter': {
      ingest: {
        name: 'Split text into sentences',
        inputTypes: [BlockInputOutputType.Text, BlockInputOutputType.Index],
        outputTypes: [BlockInputOutputType.Index],
      },
      workflow: {
        name: 'Split text into sentences',
        inputTypes: [BlockInputOutputType.Text],
        outputTypes: [BlockInputOutputType.Text],
      },
    },
    'word-splitter': {
      ingest: {
        name: 'Split text into words',
        inputTypes: [BlockInputOutputType.Text, BlockInputOutputType.Index],
        outputTypes: [BlockInputOutputType.Index],
        attributes: {
          size: 250,
          overlap: 50,
        },
      },
      workflow: {
        name: 'Split text into words',
        inputTypes: [BlockInputOutputType.Text],
        outputTypes: [BlockInputOutputType.Text],
        attributes: {
          size: 250,
          overlap: 50,
        },
      },
    },
    'entity-extractor': {
      ingest: {
        name: 'Extract entities from text',
        inputTypes: [BlockInputOutputType.Index],
        outputTypes: [BlockInputOutputType.Entity],
        attributes: {
          ner: true,
          noun: false,
          propn: false,
          verb: false,
        },
      },
      retrieve: {
        name: 'Extract entities from text',
        inputTypes: [BlockInputOutputType.Text, BlockInputOutputType.Search],
        outputTypes: [BlockInputOutputType.Entity],
        attributes: {
          ner: true,
          noun: false,
          propn: false,
          verb: false,
        },
      },
    },
    'relation-extractor': {
      ingest: {
        name: 'Extract relations from text',
        inputTypes: [BlockInputOutputType.Index, BlockInputOutputType.Entity],
        outputTypes: [BlockInputOutputType.Relation],
      },
    },
    'reranker': {
      retrieve: {
        name: 'Rerank context',
        inputTypes: [BlockInputOutputType.Text, BlockInputOutputType.Search],
        outputTypes: [BlockInputOutputType.Search],
        attributes: {
          minRelevance: 0.2,
          topK: 5,
        },
      },
    },
  };
}
