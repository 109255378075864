import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { LayoutBuilder, SDK } from '@crewdle/web-sdk';
import { ICluster, IObjectStoreBucketOptions, IObjectStoreBucket, ObjectDescriptor, ObjectKind, PayloadAction, IKeyValueDatabase, IDatabaseLayout, NodeType, IFile } from '@crewdle/web-sdk-types';

import { FirebaseService } from 'src/app/services/firebase/firebase.service';

@Injectable({
  providedIn: 'root'
})
export class SDKService {
  private sdk?: SDK;
  private clusterId?: string;
  private cluster?: ICluster;
  private buckets: Map<string, Map<string, IObjectStoreBucket >> = new Map();
  private databases: Map<string, Map<string, IKeyValueDatabase>> = new Map();
  private numberOfMistlets = 0;
  private mistletListeners: { onJoin?: () => void; onLeave?: () => void } = {};

  constructor(
    private firebase: FirebaseService,
  ) { }

  public async getSDK(vendorId: string, accessToken: string): Promise<SDK> {
    if (this.sdk) {
      return this.sdk;
    }

    this.sdk = await SDK.getInstance(vendorId, accessToken, NodeType.User, {
      minConnections: 3,
    });

    // TODO - We should generate a Firestore claim to get an authenticated user id
    // See https://firebase.google.com/docs/auth/admin/custom-claims
    const userId = this.getUniqueId();
    await this.sdk.authenticateUser({
      id: userId,
      displayName: this.firebase.auth.currentUser?.displayName || '',
      email: this.firebase.auth.currentUser?.email || '',
    });

    return this.sdk;
  }

  public async closeSDK(retryCount = 0): Promise<void> {
    try {
      if (this.sdk) {
        await this.sdk.close();
        if (this.clusterId) {
          this.cleanCluster();
        }
        this.sdk = undefined;
      }
    } catch (e) {
      if (retryCount > 3) {
        console.error('Error closing SDK: Force closing SDK.', e);

        this.sdk?.close({ force: true })
        return;
      }

      await new Promise((resolve) => setTimeout(resolve, 30000));
      await this.closeSDK(retryCount + 1);
    }
  }

  public async joinCluster(clusterId: string): Promise<ICluster> {
    if (!this.sdk) {
      throw new Error('SDK not initialized');
    }


    if (clusterId === this.clusterId && this.cluster) {
      return this.cluster;
    }

    // Leave the existing cluster if already joined
    if (this.clusterId) {
      await this.leaveCluster(this.clusterId);
    }

    try {
      this.cluster = await this.sdk.joinCluster(clusterId);
      this.clusterId = clusterId;
    } catch (e) {
      console.error('Error joining cluster', e);
      throw e;
    }

    return this.cluster;
  }

  public async leaveCluster(clusterId?: string): Promise<void> {
    if (!this.sdk) {
      throw new Error('SDK not initialized');
    }

    clusterId = clusterId ?? this.clusterId;

    if (!this.cluster || !this.clusterId || this.clusterId !== clusterId) {
      return;
    }

    this.cluster?.leave();
    this.cleanCluster(true);
 }

  public trackMistlets(cluster: ICluster, listeners: { onJoin?: () => void; onLeave?: () => void }): void {
    this.mistletListeners = listeners;

    cluster.on('agent-join', () => {
      this.numberOfMistlets++;
      if (this.numberOfMistlets > 0 && this.mistletListeners.onJoin) {
        this.mistletListeners.onJoin();
      }
    });

    cluster.on('agent-leave', () => {
      this.numberOfMistlets--;
      if (this.numberOfMistlets === 0 && this.mistletListeners.onLeave) {
        this.mistletListeners.onLeave();
      }
    });
  }

  public getNumberOfMistlets(): number {
    return this.numberOfMistlets;
  }

  public async openObjectStoreBucket(clusterId: string, bucketId: string, options: IObjectStoreBucketOptions = { syncContent: false }): Promise<IObjectStoreBucket> {
    if (!this.sdk) {
      throw new Error('SDK not initialized');
    }

    if (!this.cluster) {
      throw new Error('Cluster not joined');
    }

    const existingBucket = this.buckets?.get(clusterId)?.get(bucketId);
    if (existingBucket) {
      return existingBucket;
    }

    const bucket = await this.cluster.openObjectStoreBucket(bucketId, options);
    if (!bucket) {
      throw new Error('Bucket not found');
    }

    if (!this.buckets.has(clusterId)) {
      this.buckets.set(clusterId, new Map());
    }

    const clusterBuckets = this.buckets.get(clusterId);
    if (clusterBuckets) {
      clusterBuckets.set(bucketId, bucket);
    }

    return bucket;
  }

  public async listObjects(bucketId: string, path: string): Promise<ObjectDescriptor[]> {
    if (!this.sdk) {
      throw new Error('SDK not initialized');
    }

    if (!this.clusterId) {
      throw new Error('Cluster not joined');
    }

    const bucket = this.buckets.get(this.clusterId)?.get(bucketId);

    if (!bucket) {
      throw new Error('Bucket not opened');
    }

    const content = await bucket.list('/', { recursive: true, includeSyncingFiles: true });

    function recursiveSort(items: ObjectDescriptor[]): ObjectDescriptor[] {
      return items.sort((a, b) => {
        if (a.kind === b.kind) {
          return a.name.localeCompare(b.name);
        } else if (a.kind === ObjectKind.Folder) {
          return -1;
        } else {
          return 1;
        }
      }).map(item => {
        if (item.kind === ObjectKind.Folder && item.entries) {
          item.entries = recursiveSort(item.entries);
        }
        return item;
      });
    }

    return recursiveSort(content);
  }

  public getObject(bucketId: string, pathName: string): Promise<IFile | undefined> {
    if (!this.sdk) {
      throw new Error('SDK not initialized');
    }

    if (!this.clusterId) {
      throw new Error('Cluster not joined');
    }

    const bucket = this.buckets.get(this.clusterId)?.get(bucketId);

    if (!bucket) {
      throw new Error('Bucket not opened');
    }

    return bucket.get(pathName);
  }

  public uploadObject(bucketId: string, path: string, file: File): Promise<void> {
    if (!this.sdk) {
      throw new Error('SDK not initialized');
    }

    if (!this.clusterId) {
      throw new Error('Cluster not joined');
    }

    const bucket = this.buckets.get(this.clusterId)?.get(bucketId);

    if (!bucket) {
      throw new Error('Bucket not opened');
    }

    return bucket.publish({
      action: PayloadAction.File,
      path,
      file,
    });
  }

  public deleteObject(bucketId: string, path: string, name: string): Promise<void> {
    if (!this.sdk) {
      throw new Error('SDK not initialized');
    }

    if (!this.clusterId) {
      throw new Error('Cluster not joined');
    }

    const bucket = this.buckets.get(this.clusterId)?.get(bucketId);

    if (!bucket) {
      throw new Error('Bucket not opened');
    }

    const pathName = path === '/' || path.endsWith('/') ? path + name : path + '/' + name;
    return bucket.unpublish(pathName);
  }

  public createFolder(bucketId: string, path: string): Promise<void> {
    if (!this.sdk) {
      throw new Error('SDK not initialized');
    }

    if (!this.clusterId) {
      throw new Error('Cluster not joined');
    }

    const bucket = this.buckets.get(this.clusterId)?.get(bucketId);

    if (!bucket) {
      throw new Error('Bucket not opened');
    }

    return bucket.publish({
      action: PayloadAction.Folder,
      path,
    });
  }

  public moveObject(bucketId: string, sourcePath: string, targetPath: string, name: string): Promise<void> {
    if (!this.sdk) {
      throw new Error('SDK not initialized');
    }

    if (!this.clusterId) {
      throw new Error('Cluster not joined');
    }

    const bucket = this.buckets.get(this.clusterId)?.get(bucketId);

    if (!bucket) {
      throw new Error('Bucket not opened');
    }

    const sourcePathName = sourcePath === '/' || sourcePath.endsWith('/') ? sourcePath + name : sourcePath + '/' + name;
    const targetPathName = targetPath === '/' || targetPath.endsWith('/') ? targetPath + name : targetPath + '/' + name;
    return bucket.publish({
      action: PayloadAction.Move,
      path: sourcePathName,
      newPath: targetPathName,
    });
  }

  public async closeObjectStoreBucket(clusterId: string, bucketId: string): Promise<void> {
    if (!this.sdk) {
      throw new Error('SDK not initialized');
    }

    if (!this.cluster) {
      throw new Error('Cluster not joined');
    }

    const bucket = this.buckets.get(clusterId)?.get(bucketId);
    if (!bucket) {
      throw new Error('Bucket not opened');
    }

    bucket.close();

    this.buckets.get(clusterId)?.delete(bucketId);
  }

  public async cleanupModel(modelId: string, workflows: any[]): Promise<void> {
    if (!this.sdk) {
      throw new Error('SDK not initialized');
    }

    const buckets = [];
    const queuedBuckets = new Set<string>();

    for (const workflow of workflows) {
      const { bucketId, vpmId } = workflow;
      if (queuedBuckets.has(bucketId)) {
      continue;
      }

      if (bucketId && vpmId) {
      buckets.push({ bucketId, vpmId });
      queuedBuckets.add(bucketId);
      }
    }

    const clusters = new Map<string, Set<string>>();

    for (const { bucketId, vpmId } of buckets) {
      if (!clusters.has(vpmId)) {
      clusters.set(vpmId, new Set<string>());
      }
      clusters.get(vpmId)?.add(bucketId);
    }


    for (const [vpmId, bucketIds] of clusters) {
      try {
        await this.joinCluster(vpmId);
        for (const bucketId of bucketIds) {
          await this.openObjectStoreBucket(vpmId, bucketId);
          // TODO - We might need to support more than .gguf files
          await this.deleteObject(bucketId, '/', modelId + '.gguf');
        }

        await this.leaveCluster(vpmId);
      } catch (e) {}
    }
  }

  public async openKeyValueDatabase(clusterId: string, databaseId: string, layout: IDatabaseLayout | LayoutBuilder): Promise<IKeyValueDatabase> {
    if (!this.sdk) {
      throw new Error('SDK not initialized');
    }

    if (!this.cluster) {
      throw new Error('Cluster not joined');
    }

    const database = this.databases.get(clusterId)?.get(databaseId);

    if (database) {
      return database;
    }

    const newDatabase = await this.cluster.openKeyValueDatabase(databaseId, layout);

    if (!this.databases.has(clusterId)) {
      this.databases.set(clusterId, new Map());
    }

    const clusterDatabases = this.databases.get(clusterId);
    if (clusterDatabases) {
      clusterDatabases.set(databaseId, newDatabase);
    }

    return newDatabase;
  }

  private cleanCluster(closeBuckets = false): void {
    delete this.clusterId;
    delete this.cluster;

    for (const [_, clusterBuckets] of this.buckets) {
      for (const [bucketId, bucket] of clusterBuckets) {
        if (closeBuckets) {
          bucket.close();
        }
        clusterBuckets.delete(bucketId);
      }
    }
  }

  private getUniqueId(): string {
    if (window.name.length === 0) {
      window.name = this.generateUniqueId(6);
    }

    const sessionUniqueId = sessionStorage.getItem(`sdk-user-id-${window.name}`);
    if (sessionUniqueId) {
      return sessionUniqueId;
    }

    const uniqueId = this.generateUniqueId();

    sessionStorage.setItem(`sdk-user-id-${window.name}`, uniqueId);

    return uniqueId;
  }

  private generateUniqueId(length = 28): string {
    return uuidv4().replace(/-/g, '').slice(0, length);
  }
}
